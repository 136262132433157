
import { Component, Vue } from 'vue-property-decorator'
import { orderStatusFilter, checkResultFilter, parseTime, getOptions, orderStatusMap } from '@/utils/filters'
import { getOrderPage, getOrderReport, OrderInfo, resendAuthSms } from '@/api/order'
import { getOfficeList } from '@/api/office'

import InputSearch from '@/components/InputSearch.vue'
import type { IOption, IPagination } from '@/utils/interface'
import { getJobList } from '@/api/jobs'
import { downloadByUrl } from '@panstar/common'

@Component({
  components: { InputSearch },
  name: 'Candidate',
  filters: {
    orderStatusFilter,
    checkResultFilter,
    parseTime: (t: string) => parseTime(t, 'yyyy-MM-dd hh:mm'),
  },
})
export default class extends Vue {
  listLoading = true
  listQuery = {
    filter: ''
  }
  filterParam = {
    status: '',
    positionId:  '',
    officeId: '',
  }

  /** 分页器 */
  pagination: IPagination<OrderInfo> = {
    total: 0,
    pageNum: 1,
    pageSize: 10,
    list: [],
  }

  officeList: IOption[] = []
  jobList: IOption[] = []
  orderStatusList: IOption[] = getOptions(orderStatusMap)
  /** 部门筛选条件 */
  filterOfficeValue: string[] = []
  /** 职位筛选条件 */
  filterPositionValue: string[] = []
  /** 背调进度筛选条件 */
  filterStatusnValue: string[] = []
  /** 选中的行 */
  rowSelection: OrderInfo[] = []

  /** 是否存在报告 */
  hasReport(row: OrderInfo) {
    return row.status === '07'
  }

  /** 是否处于授权阶段 */
  isAuthStage(row: OrderInfo) {
    return row.status === '00' || row.status === '01'
  }

  onSearch(val: string) {
    // console.log('val', val)
    this.listQuery.filter = val
    this.pagination.pageNum = 1
    this.getList()
  }

  doSearch() {
    this.pagination.pageNum = 1
    this.getList()
  }

  async getOfficeList() {
    this.officeList = (await getOfficeList()).map(i => {
      return {
        text: i.officeName,
        value: i.officeId,
      }
    })
  }

  async getJobList() {
    this.jobList = (await getJobList()).map(i => {
      return {
        text: i.positionName,
        value: i.positionId,
      }
    })
  }

  async getList() {
    this.listLoading = true
    const { pageNum, pageSize } = this.pagination
    const { recordList, totalCount } = await getOrderPage({
      ...this.listQuery,
      status: this.filterParam.status,
      positionId:  this.filterParam.positionId,
      officeId: this.filterParam.officeId,
      pageNum,
      pageSize,
    })
    this.pagination.list = recordList
    this.pagination.total = totalCount
    this.listLoading = false
    // console.log('aaa', recordList)
  }

  filterHandlerOffice(value: any, row: OrderInfo) {
    // console.log(row, value)
    return row.officeId === value
  }

  filterHandler(value: any, row: any, column: any) {
    const property = column.property
    return row[property] === value
  }

  handleSelectionChange(val: OrderInfo[]) {
    this.rowSelection = val
  }

  /** 下载报告 */
  onDownload(item?: OrderInfo) {
    const list = item ? [item] : this.rowSelection
    list.forEach(i => {
      getOrderReport(i.reportId).then(info => {
        downloadByUrl(info.fileUrl)
      })
    })
  }

  /** 重新发送授权短信 */
  onResendSms(item?: OrderInfo) {
    const list = item ? [item] : this.rowSelection
    list.forEach(i => {
      resendAuthSms({'orderId': i.orderId}).then(arr => {
          if(arr){
            console.log(arr)
          }
          this.$message({ message: '发送成功', type: 'success' })
      })
    })
  }

  created() {
    const { params } = this.$route
    if (params.officeId) this.filterOfficeValue = [params.officeId]
    if (params.positionId) this.filterPositionValue = [params.positionId]
    if (params.status) this.filterStatusnValue = [params.status]

    this.getList()
    this.getOfficeList()
    this.getJobList()
  }
}
